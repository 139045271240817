<template>
  <div>
    <iframe src="https://map.baidu.com/" width="900" height="600" style="border:0;background:none;"></iframe>
  </div>
</template>

<script>
export default {
  name: 'index',
  data() {
    return {
      iframeSrc: ''
    }
  }
}
</script>

<style scoped></style>
